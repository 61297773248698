export default {
  name: "OrdersComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      tableData: [],
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      screenWidth: 0,
      texts: "",
      tab: null,
    };
  },
  beforeMount() {
    this.$store.commit('setMenuSidebar', {active:false})
    this.texts = FILE.subadminTexts[this.selectLanguage];
  },
  mounted() {
    this.fillTable();
  },
  created() {
    window.addEventListener("load", this.contentTableHeight)
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // consulta el detalle del administrador
    detailItemUser(item) {
      this.$store.commit("setDialogDetailSubadmin", {
        active: true,
        arr: item,
      });
    },
    // muestra dialog para modificar la contrasena
    dialogPasswordChange(item) {
      this.$store.commit("setPasswordChange", {
        api: `${URI}/api/v1/${this.selectLanguage}/administrators/${item.sAdministratorId}/password`,
        active: true,
      });
    },
    // cierra el modal en caso de un killsession
    emitClose() {
      this.$store.commit("setDialogDetailSubadmin", {
        active: false,
        arr: [],
      });
    },
    // muesta los administradores disponbiles
    fillTable() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/administrators`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          bVerified: this.bUserState
          // tStart: this.startDate,
          // tEnd: this.endDate,
        },
      })
        .then((response) => {
          this.tableData = response.data.results
          .map((e) => {
            return {
              ...e,
              nameFull: e.sName + ' ' + e.sLastname
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
      if (window.innerWidth > 900) {
        this.screenWidth = window.innerWidth - 140;
      } else {
        this.screenWidth = window.innerWidth - 40;
      }

    },

  },
  computed: {
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    bUserState() {
      return this.$store.state.bUserState;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
  },
  watch: {
    refreshTable: function () {
      this.fillTable();
    },
    sSearch: function () {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    bUserState() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    iCurrentPage: function () {
      this.fillTable();
    },
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.subadminTexts[this.selectLanguage];
        this.fillTable();

      }
    },
  },
};